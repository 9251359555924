export const Constants = {
  appTitle: 'DCODE',

  content: 'Content',
  diagnosticSpecificatiosn: 'Diagnostic Specifications',
  diagnosticFamilies: 'Diagnostic Families',
  identificationGroups: 'Identification Groups',
  sharedFiles: 'Shared Files',
  vsoc: 'Vsoc',

  export: 'Export',
  packages: 'Packages',
  label: 'Label',

  rbaccHeading: 'Rbacc Files',
  rbaccSubHeading: 'Rbacc is Role based access control. You can upload Rbacc file here to filter the request to executed to ECU by user roles.',
  wikiUrl: 'https://scaniaazureservices.sharepoint.com/sites/GadgetHelpdesk/_layouts/15/Doc.aspx?sourcedoc={dcf7c597-724e-4609-b219-9a9d2cec0ae6}&action=edit&wd=target%28DCode.one%7C2e949af4-9e9b-4f6c-b692-3dfc6bbf091e%2FNew%20RBACC%7C692aa34f-1358-4a90-80a4-62d8f89b277f%2F%29&wdorigin=703',
  protocol: 'Protocol',
  generations: 'Generations',
  noLogsMsg: 'No change logs available.',
  labelHeading: 'Export Label',
  logout: 'Logout',

  specifications: 'Specifications',
  specificationslabel: 'Specifications Label',
  selectedGeneration: 'Selected Generation',

  newBuildLabelText: 'Add new build',
  newLabelDescription: 'When creating a build label, all new specifications/specification versions that have both the Main label and are marked for Release will get this label.A build label within DCODE will not affect the build label for the production SCOMMDb build.',
  notFound: 'No data found/available',
  specificationVersionTab: 'Specification Versions',
  serverIdentificationTab: 'Server Identifications',
  identificationGroup: ' Identification groups order can be changed from the',
  listView: 'list',
  cardView: 'card',
  identification: 'Identification',
  expression: 'Expression',
  specification: 'Specification',
  version: 'Version',
  confirmDelete: 'Confirm Deletion',
  subheading: 'Select a yaml file to upload:',
  confirmDeleteMessage: 'Are you sure you want to delete this item?',
  heading: 'Manage Yaml',
  subheadingYaml: 'Select a yaml file to upload:',
  validFileMessage: 'There are no errors in this Rbacc File.File is validated',
  confirmProceedMessage: 'Are you sure you want to proceed with this action?',
  confirmTitle : 'Confirm Deletion',
  confirmMessage :'Are you sure you want to delete this item?',
  includeSecurityAccessLabel:'Include security access keys'
};

export const Roles = { 
    ADMIN : "Admin",
    SYSTEMOWNER : "System Owner",
    READER : "Reader",
    COLLABORATOR : "Collaborator",
    ADVANCED : "Advanced"
}

export const RolePriority: { [key: string]: number } = {
  basic: 1,
  reader: 1,
  extended: 2,
  complete: 3,
  advanced: 4,
  admin: 5,
};

export const RoleMappings: { [key: string]: string } = {
  admin: 'Admin',
  advanced: 'Advanced',
  complete: 'System Owner',
  extended: 'Collaborator',
  basic: 'Reader',
};

export enum SesammVersion {
  Six,
  Seven
}